import Vue from 'vue'
import App from './App.vue'
import router from './router'
import wayCode from './utils/wayCode'
import config from './config'
import 'amfe-flexible'
Vue.config.productionTip = false

if (wayCode.getPayWay().name == "wx") {
  if (wayCode.getPayWay().mini) {
    const oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    oScript.src = 'https://res.wx.qq.com/open/js/jweixin-1.4.0.js';
    document.body.appendChild(oScript);
  }
} else if (wayCode.getPayWay().name == "zfb") {
  if (wayCode.getPayWay().mini) {
    const oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    oScript.src = 'https://appx/web-view.min.js';
    document.body.appendChild(oScript);
  }
}

// 全局loading
import Loading from './views/loading'
Vue.use(Loading)
import vConsole from 'vconsole'
if (process.env.NODE_ENV === 'development') {
  Vue.prototype.$vConsole = new vConsole()
}
/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {

  console.log("进入路由守卫", from, to)

  if (to.meta.title) {
    document.title = to.meta.title
  }

  // from: 当前导航正要离开的路由
  // to: 即将要进入的目标路由对象

  // 如果在免登录页面则直接放行
  if (config.passGuardRouteList.includes(to.name)) {
    // 在免登录名单，直接进入
    next()
    return false
  }

  //获取不到参数
  let payParams = to.params[config.urlPayParamsName];
  let mode = to.query.mode
  // let token = 'test';  // 不提交
  console.log(payParams, mode)
  if (payParams) {  //放置token信息
    config.cachePayParams = payParams;
  }
  if (mode) {
    config.mode = mode
  }

  // if (!to.query.qrCode && !to.query.payOrderNo) {
  //   next({ name: config.errorPageRouteName, params: { errInfo: "请通过二维码进入支付页面！" } })
  //   return false;
  // }

  if (!config.cachePayParams) {
    next({ name: config.errorPageRouteName, params: { errInfo: "请通过二维码进入支付页面！" } })
    return false;
  }

  //获取不到支付类型, 需要跳转到错误页面
  if (!wayCode.getPayWay()) {
    next({ name: config.errorPageRouteName, params: { errInfo: "不支持的支付方式！ 请在微信/支付宝/银联应用内扫码进入！" } })
    return false;
  }

  next()
})


Vue.prototype.$config = config;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
