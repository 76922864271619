<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import wayCodeUtils from "@/utils/wayCode";
export default {
  mounted() {
    console.log('20231206');
    if (wayCodeUtils.getPayWay().mini) {
      const that = this
      // 监听返回
      this.pushHistory()
      window.addEventListener('popstate', function () {
        // 为了避免只调用一次
        that.pushHistory(document.title, location.href)
        // 自定义方法, 直接关闭网页或返回小程序上一页
        that.goBack()
      },
        false
      )
    }
  },
  methods: {
    // 添加一条 history 实体作为替代原来的 history 实体
    pushHistory(str = 'title', url = '#') {
      const state = {
        title: str,
        url
      }
      window.history.pushState(state, state.title, state.url)
    },
    goBack() {
      if (wayCodeUtils.getPayWay().name == "wx") {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateBack()
      } else if (wayCodeUtils.getPayWay().name == "zfb") {
        // eslint-disable-next-line no-undef
        my.navigateBack()
      }

    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
