<template>
  <!-- 打开弹框的动画 -->
  <transition name="animation">
    <div
      class="loadindWrap"
      v-if="showLoading"
      :style="{ background: backgroundColor }"
    >
      <div class="loadingContent">
        <div class="iBox">
          <!-- <i class="icon-loading"></i> -->
        </div>
        <!-- <div class="text">{{ text }}</div> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showLoading: false, // 控制显示与隐藏的标识
      backgroundColor: "rgba(0, 0, 0, 0)", // 默认背景色
      text: "加载中...", // 默认文字
    };
  },
};
</script>

<style scoped>
.loadindWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loadindWrap .loadingContent {
  background: #d8d8d8;
  padding: 30px;
  border-radius: 20px;
  color: rgb(160, 233, 66);
  text-align: center;
}
.loadindWrap .loadingContent .iBox {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-color: rgb(255, 255, 255) rgb(255, 255, 255) rgb(255, 255, 255)
    rgb(190, 190, 190);
  width: 80px;
  height: 80px;
  animation: rotate 1s linear infinite;
  /* margin-bottom: 6px; */
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadindWrap .loadingContent .iBox i {
  /* font-size: 20px; */
  color: #bfa;
}
/* 加一个过渡效果 */
/* .animation-enter,
.animation-leave-to {
  opacity: 0;
}
.animation-enter-active,
.animation-leave-active {
  transition: opacity 0.6s;
} */
</style>
