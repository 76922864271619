/**
 * 路由配置信息
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

// hack router push callback
// [解决 vue-router跳转相同路径报错 ]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
  { path: '/pages/cashier/hub/:payParams', name: 'Hub', component: () => import('../views/Hub.vue'), meta: { title: '收银台' }, }, //自动分发器
  { path: '/error', name: 'Error', component: () => import('../views/Error.vue') },
  { path: '/pages/oauth2Callback/:payParams', name: 'Oauth2Callback', component: () => import('../views/Oauth2Callback.vue') }, //oauth回调地址
  {
    path: '/pages/cashier', name: 'Cashier', component: () => import('../views/Cashier.vue'), //收银台（该地址无意义）
    meta: { title: '收银台' },
    children: [
      { path: '/pages/cashier/pay', name: 'CashierPay', component: () => import('../views/payway/Pay.vue'), meta: { title: '收银台' }, },
      // { path: '/pages/cashier/wxpay', name: 'CashierWxpay', component: () => import('../views/payway/Wxpay.vue'), meta: { title: '收银台' }, },
      // { path: '/pages/cashier/alipay', name: 'CashierAlipay', component: () => import('../views/payway/Alipay.vue'), meta: { title: '收银台' }, },
      // { path: '/pages/cashier/ysfpay', name: 'CashierYsfpay', component: () => import('../views/payway/Ysfpay.vue'), meta: { title: '收银台' }, }
      { path: '/pages/cashier/med-cashier', name: 'MedCashier', component: () => import('../views/payway/MedCashier.vue'), meta: { title: '收银台' } }
    ]
  },
  {
    path: '/pages/return', name: 'Return', component: () => import('../views/Return.vue'),
    meta: { title: '订单结果' },
    children: [
      { path: '/pages/return/wxpay', name: 'ReturnWxpay', component: () => import('../views/return/Wxpay.vue'), meta: { title: '订单结果' }, },
      { path: '/pages/return/alipay', name: 'ReturnAlipay', component: () => import('../views/return/Alipay.vue'), meta: { title: '订单结果' }, },
    ]
  }
]

const router = new VueRouter({
  mode: 'history', //history 需要nginx适配    hash：是#的格式。
  base: "",
  routes
})

export default router
