/*
 *  全局定义信息
 */

const errorPageRouteName = 'Error' //错误页面名称定义
const passGuardRouteList = [errorPageRouteName, 'Return', 'ReturnWxpay', 'ReturnAlipay']  // 不进入路由守卫的name

/** 定义支付方式 **/
const payWay = {
    WXPAY: { wayCode: "WX_JSAPI", wayName: '微信支付', beforeName: 'WX_', routeName: "CashierPay", name: 'wx', color: '#07c160', mini: false, returnPage: 'ReturnWxpay' },
    ALIPAY: { wayCode: "ALI_JSAPI", wayName: '支付宝', beforeName: 'ALI_', routeName: "CashierPay", name: 'zfb', color: '#1678ff', mini: false, returnPage: 'ReturnAlipay' },
    WXPAY_MINI: { wayCode: "WX_LITE", wayName: '微信支付', beforeName: 'WX_', routeName: "CashierPay", name: 'wx', color: '#07c160', mini: true, returnPage: 'ReturnWxpay' },
    ALIPAY_MINI: { wayCode: "ALI_APP", wayName: '支付宝', beforeName: 'ALI_', routeName: "CashierPay", name: 'zfb', color: '#1678ff', mini: true, returnPage: 'ReturnAlipay' },
    YSFPAY: { wayCode: 'YSF_JSAPI', wayName: '云闪付', beforeName: 'YSF_', routeName: "CashierPay", name: 'ysf' }
}
 
// 支付订单参数:
// {
//   "payOrderNo": "",  支付订单号(不可空)
//   "openId": ""       openId (可空)
// }

// payParams :  为支付订单参数 aes加密后的值
 


export default {
    errorPageRouteName: errorPageRouteName,
    passGuardRouteList: passGuardRouteList,
    urlPayParamsName: "payParams", //URL传递的支付订单参数
    payWay: payWay,
    cachePayParams: "",
    mode: ""
}