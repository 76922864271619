/**
 * 获取支付方式工具类
 */

import config from '@/config'

const getToPageRouteName = function () {
    const payWay = getPayWay();
    return payWay ? payWay.routeName : null
}

const getPayWay = function () {

    const userAgent = navigator.userAgent;

    if (userAgent.indexOf("MicroMessenger") >= 0) {
        if (userAgent.toLocaleLowerCase().indexOf('miniprogram') >= 0) {
            return config.payWay.WXPAY_MINI
        } else {
            return config.payWay.WXPAY;
        }
    }

    if (userAgent.indexOf("AlipayClient") >= 0) {
        if (userAgent.toLocaleLowerCase().indexOf('miniprogram') >= 0) {
            return config.payWay.ALIPAY_MINI;
        } else {
            return config.payWay.ALIPAY;
        }
    }
    // NODE_ENV=development
    if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_DEV_WAY_CODE) {
        return config.payWay.WXPAY;
    }
    return config.payWay.YSFPAY;

}

export default {
    getToPageRouteName: getToPageRouteName,
    getPayWay: getPayWay

}
